const INFO = {
	main: {
		title: "M.Parracho",
		name: "Matheus Ramos Parracho",
		email: "mramosparracho@gmail.com",
		logo: "../logo.png",
	},

	socials: {
		//twitter: "https://twitter.com/",
		github: "https://github.com/mathparracho",
		linkedin: "https://www.linkedin.com/in/matheusparracho",
		medium: "https://medium.com/@mathparracho",
		kaggle: "https://www.kaggle.com/matheusparracho",
		//instagram: "https://instagram.com/",
		//stackoverflow: "https://stackoverflow.com/",
		//facebook: "https://facebook.com/",
	},

	homepage: {
		title: "Hello, I am Matheus. Nice to meet you!",
		description:
			"Here you found a guy with an insatiable curiosity for discovering new technological paradigms in AI. Specialized in Computer Vision, Forecasting, and Language Processing, I hold a Master’s from IP Paris in Computer Vision and am in the final stretch of my studies at the Federal University of Rio de Janeiro.",
	},

	about: {
		title: "I am Matheus Ramos Parracho. I am currently in Rio de Janeiro, nice to meet you!",
		description:
		(
			<>
			  I am an AI Engineer with a strong foundation in Computer Vision, having completed my Master’s degree in Paris.<br />
			  My journey included several projects and an internship at General Electrics Healthcare, where I worked on a cutting-edge project in the field of AI for Healthcare.<br />
			  Currently, I am expanding my expertise beyond Computer Vision, going into Forecasting and Natural Language Processing (NLP).<br />
			  I actively develop projects in these domains, continuously pushing the boundaries of what AI can achieve.<br />
			  I also engage with the AI community by sharing articles, insights, and opinions, contributing to the collective growth and innovation in the field.<br />
			  Also, I am a Kaggle lover!
			  <p style={{ fontSize: '1.4em', fontWeight: 'bold' }}>Experience</p>
			  <ul>
					<li>AI Engineer Intership at GE Healthcare - Paris Region</li>
					<li>Embedded Systems Researcher at the Waves and Currents Laboratory (LOC) - UFRJ, Rio de Janeiro, Brazil</li>
					<li>Avionics Engineer at Minerva Rockets - UFRJ, Rio de Janeiro, Brazil</li>
					<li>Co-Founder at Semppo - Rio de Janeiro, Brazil</li>
					<li>Data Engineer at Scoutfy - Part-time, São Paulo, Brazil</li>
				</ul>
			  <p style={{ fontSize: '1.4em', fontWeight: 'bold' }}>Education</p>
			  <p style={{ fontSize: '1.2em', fontWeight: 'bold' }}>Télécom SudParis - IPParis</p>Project-based master with focus on projects using Image Processing and Neural Networks:
				<ul>
					<li>Developed a pipeline to segment liver lesions using UNET</li>
					<li>Face Recognition system using Siamese Neural Networks and GANs to generate more data</li>
					<li>End-of-studies project based on brain tumor segmentation using 3D SWIN UNETR (SWIN Vision Transformers + 3D Decoder UNET)</li>
				</ul>
				<p style={{ fontSize: '1.2em', fontWeight: 'bold' }}>Computers and Electronics Engineering - Federal University of Rio de Janeiro (UFRJ), Brazil</p>
				You can find more details on my Linkedin page.
			</>
		  )},

	articles: {
		title: "I'm passionate about pushing the boundaries of what's possible.",
		description:
			"Chronological collection of my long-form thoughts on the field of Artificial Intelligence. All the articles written here are also available on my Medium page: https://medium.com/@mathparracho",
	},

	projects: [
		{
			title: "Optimizing Siamese Convolutional Neural Networks: BCE x Contrastive x Triplet x Quadruplet Losses",
			description:
				"In this article, I explore four loss functions: Binary Cross-Entropy (BCE), Contrastive, Triplet, and Quadruplet. Finally, I analyze their impact on the model's overall performance using the MNIST dataset.",
			logo: "https://www.svgrepo.com/show/354057/medium-icon.svg",
			linkText: "View Project",
			link: "https://medium.com/@mathparracho/optimizing-siamese-convolutional-neural-networks-bce-x-contrastive-x-triplet-x-quadruplet-losses-317922963ddc",
		},
		{
			title: "Skin Cancer Detection using Convolutional Neural Networks and Gradient Boosting Methods",
			description:
				"In this Medium article, I am going to explain step-by-step what were the ideas from my tentative and in the end we are going to make a comparison between the results from the CNN and the GBM.",
			logo: "https://www.svgrepo.com/show/354057/medium-icon.svg",
			linkText: "View Project",
			link: "https://medium.com/@mathparracho/skin-cancer-detection-using-convolutional-neural-networks-and-gradient-boosting-methods-816c4cafc9f5",
		},
		{
			title: "Solving CAPTCHAs with Convolutional Neural Networks",
			description:
				"Developing a Convolutional Neural Network (CNN) to solve CAPTCHAs involves overcoming the challenges posed by noise, distortion, and anti-OCR techniques. This approach demonstrates how advanced AI can tackle complex recognition tasks, offering insights into data preparation, network architecture, and the potential of CNNs in automating previously unsolvable problems.",
			logo: "https://www.svgrepo.com/show/354057/medium-icon.svg",
			linkText: "View Project",
			link: "https://medium.com/@mathparracho/solving-captchas-with-convolutional-neural-networks-89debcc65f55",
		},
		{
			title: "Heart Segmentation — Neural Networks for Medical Imaging",
			description:
				"This study provides insights into medical imaging segmentation by using MONAI and a 3D-UNET model to identify the left atrium in MRI heart volumes from the DECATHLON dataset. It addresses challenges like small data size and label imbalance, detailing the data preprocessing, model architecture, and training processes, highlighting the potential of deep learning in advancing medical image analysis.",
			logo: "https://www.svgrepo.com/show/354057/medium-icon.svg",
			linkText: "View Project",
			link: "https://medium.com/@mathparracho/heart-segmentation-neural-networks-for-medical-imaging-a75138906ed1",
		},
		{
			title: "Autoencoders and Latent Space: Studying their power for data compression",
			description:
				"Autoencoders, a neural network architecture, have shown exceptional effectiveness in data compression by encoding data into a low-dimensional latent space. This article demonstrates the construction and training of a stacked autoencoder using the MNIST dataset, comparing the performance of different latent space dimensions, and highlighting the trade-off between data compression and reconstruction quality.",
			logo: "https://www.svgrepo.com/show/354057/medium-icon.svg",
			linkText: "View Project",
			link: "https://medium.com/@mathparracho/autoencoders-and-latent-space-studying-their-power-for-data-compression-7f6b59d9694b",
		},
		{
			title: "Creating a Convolutional Neural Network to approach the MNIST dataset",
			description:
				"The MNIST dataset is already a well-known dataset to play around with different algorithms and try to score the highest possible score. The goal here is to predict what number is shown in the image using computer vision.",
			logo: "https://www.svgrepo.com/show/354057/medium-icon.svg",
			linkText: "View Project",
			link: "https://medium.com/@mathparracho/creating-a-convolutional-neural-network-to-approach-the-mnist-dataset-9e80c210a89d",
		},
		{
			title: "Building the Infrastructure of a Social Mobile App: MaskUid",
			description:
				"MaskUid is an anonymous social media app designed to allow users to post within a 100-meter radius anonymously for 24 hours. Developed using React Native and hosted on GCP, the app focuses on the social dynamics of anonymous posting in local environments.",
			logo: "https://www.svgrepo.com/show/354057/medium-icon.svg",
			linkText: "View Project",
			link: "https://medium.com/@mathparracho/maskuid-building-the-infrastructure-of-a-social-mobile-app-9e9d04f7b2d",
		},
		{
			title: "Prantos: Building a Full-Stack Web-Based Sheet Music to Audio Converter",
			description:
				"PRANTOS is a web system integrating deep learning for Optical Music Recognition (OMR) to convert music sheet images into GUIDO and WAV files. This project, developed using Flask for the backend and React for the frontend, demonstrates the application of machine learning in image processing, providing a user-friendly platform for music notation conversion and playback.",
			logo: "https://www.svgrepo.com/show/354057/medium-icon.svg",
			linkText: "View Project",
			link: "https://medium.com/@mathparracho/prantos-building-a-full-stack-web-based-sheet-music-to-audio-converter-6243e5a11b23",
		},
		{
			title: "I built my own Alexa with some different features",
			description:
				"Built with ESP-32 modules, a custom WhatsApp API, and a Dockerized server, Axela manages various tasks through voice and text commands. This article outlines the development, communication protocols, and features of Axela, highlighting its potential to enhance everyday living through automation and smart technology integration.",
			logo: "https://www.svgrepo.com/show/354057/medium-icon.svg",
			linkText: "View Project",
			link: "https://medium.com/@mathparracho/i-built-my-own-alexa-with-different-features-ff22fa0f3d44",
		},
		{
			title: "PRANTOS - An Optimal Music Recognition System - IPParis Study Report",
			description:
				"PRANTOS is a web system integrating deep learning for Optical Music Recognition (OMR) to convert music sheet images into GUIDO and WAV files. This project, developed using Flask for the backend and React for the frontend, demonstrates the application of machine learning in image processing, providing a user-friendly platform for music notation conversion and playback.",
			logo: "https://upload.wikimedia.org/wikipedia/commons/8/87/PDF_file_icon.svg",
			linkText: "View Project",
			link: "https://drive.google.com/file/d/13Xf0mrMvZ7A2EJwmO5r2_vJHHtSasVOe/view?usp=sharing",
		},
		{
			title: "Image Encoding Methods - IPParis Study Report",
			description:
				"Studying the performance of different encoding techniques for imaging data",
			logo: "https://upload.wikimedia.org/wikipedia/commons/8/87/PDF_file_icon.svg",
			linkText: "View Project",
			link: "https://drive.google.com/file/d/1tC6znPe3ycotbPT0B4pKU8UyuKLakNdk/view?usp=sharing",
		},
		{
			title: "Morphological Segmentation - IPParis Study Report",
			description:
				"Studying different Morphological Segmentation tools applied in image processing",
			logo: "https://upload.wikimedia.org/wikipedia/commons/8/87/PDF_file_icon.svg",
			linkText: "View Project",
			link: "https://drive.google.com/file/d/1IQpAJ_OsiPrtr9rIPkbwLADxkMvNBHIr/view?usp=sharing",
		},
		{
			title: "SIFT Analysis for Imaging Feature Extraction - IPParis Study Report",
			description:
				"Studying the impact of applying SIFT for extracting features from images",
			logo: "https://upload.wikimedia.org/wikipedia/commons/8/87/PDF_file_icon.svg",
			linkText: "View Project",
			link: "https://drive.google.com/file/d/1Ka2E_36yaZCAGpTI5IND20yQNxLmDJ8R/view?usp=sharing",
		},
		{
			title: "Madrid Traffic Prediction - IPParis Study Report",
			description:
				"Analysing the madrid traffic prediction, proposing a solution and comparing it to state-of-the-art methods",
			logo: "https://upload.wikimedia.org/wikipedia/commons/8/87/PDF_file_icon.svg",
			linkText: "View Project",
			link: "https://drive.google.com/file/d/1otP5kAIWj4mDAFHI14v2qFJ2QX_1z_U7/view?usp=sharing",
		},
		{
			title: "Eigenfaces for Face Indexing and Recognition - IPParis Study Report",
			description:
				"Study of the “eigenfaces” face recognition algorithm based on PCA of the luminance face images.",
			logo: "https://upload.wikimedia.org/wikipedia/commons/8/87/PDF_file_icon.svg",
			linkText: "View Project",
			link: "https://drive.google.com/file/d/15JffQ0fO74_AqCM-bLQdIRYh-yVaHZAj/view?usp=sharing",
		},
		{
			title: "Shot Boundary Detection and Key-frame Extraction - IPParis Study Report",
			description:
				"Studying video analysis for shot boundary detection (abrupt or gradual transitions between scenes) and key-frame extraction focusing on selecting representative frames.",
			logo: "https://upload.wikimedia.org/wikipedia/commons/8/87/PDF_file_icon.svg",
			linkText: "View Project",
			link: "https://drive.google.com/file/d/18dCN8_oDu6g2FPhgxXk9aBiiOEd8vwMt/view?usp=sharing",
		},
		{
			title: "Combinatory and Sequential Logic - IPParis Study Report",
			description:
				"Study of logical gates for combinatory and sequential logic",
			logo: "https://upload.wikimedia.org/wikipedia/commons/8/87/PDF_file_icon.svg",
			linkText: "View Project",
			link: "https://drive.google.com/file/d/1Tp2M-PXnv6yiZN5JFVVJXW0JmEqfoj8v/view?usp=sharing",
		},
		{
			title: "Building Filters for Signal Processing - IPParis Study Report",
			description:
				"Study the architecture of filters to signal processing according to requirements",
			logo: "https://upload.wikimedia.org/wikipedia/commons/8/87/PDF_file_icon.svg",
			linkText: "View Project",
			link: "https://drive.google.com/file/d/1ByZu0KIlcjhhVvm3nv-ylpcpSHiPJ5EF/view?usp=sharing",
		},
	],
};

export default INFO;
